import Sidebar from "@components/Sidebar";
import SupportButton from "@components/SupportButton"
import { RootState } from "@services/Redux";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const RootContainer = styled.div`
  background-color: #edf0f6;
  width: 100vw;
  display: flex;
`;

const Content = styled.div<{ is_sidebar_toggled: boolean }>`
  position: absolute;
  left: 0px;
  width: 100vw;
  overflow: auto;
  height: calc(100vh - 80px);
  @media (min-width: 992px) {
    width: calc(100vw - ${(props) => (props.is_sidebar_toggled ? "255px" : "0px")});
    left: ${({ is_sidebar_toggled }) => (is_sidebar_toggled ? "255px" : "0px")};
    transition:
      left 1s,
      width 1s;
  }
`;

const ContentContainer: React.FC<Props> = (props) => {
  const isSidebarToggle = useSelector((state: RootState) => state.app.isSidebarToggled);
  return (
    <RootContainer>
      <Sidebar />
      <Content is_sidebar_toggled={isSidebarToggle} className={"p-2 p-lg-3"} id={"content-container"}>
        {props.children}
        <SupportButton />
      </Content>
    </RootContainer>
  );
};

export default ContentContainer;
