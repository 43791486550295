import * as React from "react";
import { GiTicket } from "react-icons/gi";
import { IoIosCloseCircle } from "react-icons/io";

const SupportButton: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    isVisible && (
      <div className="support-button-container">
        <button onClick={handleClose} className="close-button">
        <IoIosCloseCircle size={24} />
        </button>
        <a href="https://vendemoda.tomticket.com/helpdesk" target="_blank" rel="noreferrer">
          <button className="support-button">
            <GiTicket /> Suporte Técnico
          </button>
        </a>
      </div>
    )
  );
};

export default SupportButton;
